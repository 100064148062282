import React, { useEffect, useReducer } from 'react';
import { initialState, reducer } from './store';
import FadeIn from 'react-fade-in';
import _ from 'underscore';
import moment from 'moment';

import FloatingButton from '../../../components/FloatingButton';
import { Header } from '../../../components/Header';
import LoaderButton from '../../../components/LoaderButton';
import CustomDatePicker from '../../../components/CustomDatePicker';
import GenericTable from '../../../components/GenericTable';
import FieldWithHistory from '../../../components/FieldWithHistory';

import {getLoginActivityLog, getUserSummary } from '../../../libs/db-lib';
import {
    usernameValidate,
    isValidEmail,
    formatTimezone,
} from '../../../libs/utils';
import {
    getRecentSearches,
    setRecentSearches,
    storeClipboard,
} from '../../../libs/utils-ts';

import {
    allFilterKeys,
} from '../../../libs/tables/logTableStructure';
import { baseLoginActivityLogColumns } from '../../../libs/tables/columnDefinitions';

import { ChildProps, InternalLog, QueriedUser } from '../../../types';

import {
    setLog,
    setGroupedLog,
    setQuery,
    setQueryText,
    setQueryError,
    setStartDate,
    setEndDate,
    setSelectedActionCodes,
    setIsLoading,
    setQueriedUser,
    setQueryType,
    setShowLog,
    setEnterPressed,
} from './store/actions';
import CopyData from '../../../components/CopyData';

let state = initialState;

const GetLoginActivityLog = (props: ChildProps) => {
    const [getLoginActivityStore, dispatch] = useReducer(reducer, state);

    const {
        log,
        queryText,
        queryType,
        queryError,
        startDate,
        endDate,
        isLoading,
        queriedUser,
        showLog,
        enterPressed,
    } = getLoginActivityStore;

    let { query } = getLoginActivityStore;

    const topRef = React.createRef<HTMLDivElement>();

    state = { ...getLoginActivityStore, isLoading: false };

    let pageData: { [k: string]: any } = {
        'Query Type': queryType,
        Query: query,
        'Date Range': `${startDate} to ${endDate}`,
        'Email Log Count': log.length,
        'Email Log': log,
    };

    storeClipboard(pageData);

    useEffect(() => {
        let groups = log.reduce((group: any, entry: any) => {
            group[entry.actionCode] = group[entry.actionCode] || [];
            group[entry.actionCode].push(entry);
            return group;
        }, Object.create(null));
        setGroupedLog(dispatch, groups);
    }, [log]);

    const handleChangeQuery = _.debounce((query: string) => {
        let error = '';
        switch (queryType) {
            case 'USERNAME':
                error = !usernameValidate(query.trim())
                    ? 'Username must be at least 8 alphanumeric lowercase characters starting with a letter'
                    : '';
                query = query.toLowerCase();
                break;
            case 'EMAIL':
                error = !isValidEmail(query.trim()) ? 'Email format is not valid!' : '';
                query = query.toLowerCase();
                break;
        }
        setQueryError(dispatch, error);
        setQuery(dispatch, query);
    }, 15);

    const handleChangeQueryType = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setQueryType(dispatch, event.target.value);
        setQueryError(dispatch, '');
        setQueryText(dispatch, '');
    };

    const handleRangeChange = (value: any) => {
        setStartDate(dispatch, value.start);
        setEndDate(dispatch, value.end);
    };

    const validateForm = () => {
        switch (queryType) {
            case 'USERNAME':
                return usernameValidate(query.trim());
            case 'EMAIL':
                return isValidEmail(query.trim());
            default:
                return query.trim().length > 0;
        }
    };

    const handleSubmit = async (e?: React.SyntheticEvent) => {
        e?.preventDefault();
        setShowLog(dispatch, false);
        setIsLoading(dispatch, true);
        setLog(dispatch, []);
        setSelectedActionCodes(dispatch, []);
        setGroupedLog(dispatch, {} as InternalLog);
        setQueriedUser(dispatch, {} as QueriedUser);

        const startDateStr = moment(startDate).startOf('day').utc().toISOString();
        // If the end date is the current date, make the end date the current moment for most up-to-date logs
        const endDateStr = moment(endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
          ? moment().utc().toISOString()
          : moment(endDate).endOf('day').utc().toISOString();

        try {
            let result:any = null;
            let userName;
                if (queryType === 'USERNAME') {
                    let user  = query.toLowerCase();
                    setQueriedUser(dispatch, { userName : user } as QueriedUser);
                    query = query.toLowerCase();
                    result = await getLoginActivityLog(
                        query,
                        startDateStr,
                        endDateStr
                );
            }  else {
                if (queryType === 'EMAIL') {
                    setQueriedUser(dispatch, { email: query } as QueriedUser);
                    let userResult = await getUserSummary(queryType, query);
                    if (!userResult || Object.hasOwn(userResult, 'error')) {
                        if ([401, 403].indexOf(userResult.error.status) === -1) {
                            props.handleShowAlert('Error', 'That email does not exist');
                        }
                        return;
                    }
                    if (userResult.userName) {
                        userName = userResult.userName;
                    }
                    result = await getLoginActivityLog(
                        userName,
                        startDateStr,
                        endDateStr
                    );
                }
            }

            if (!result || Object.hasOwn(result, 'error')) {
                if ([401, 403].indexOf(result.error.status) === -1) {
                    props.handleShowAlert('Error', result.error);
                }
                return;
            } else if (result.logEntries.length === 0) {
                props.handleShowAlert(
                    'Get Logs',
                    <span>
                        No logs found for query: <strong>{query}</strong> in that date
                        range.
                    </span>,
                    false
                );
            } else {
                setLog(dispatch, result.logEntries);
                setShowLog(dispatch, true);
            }
        } catch (e: any) {
            props.handleShowAlert('Error', e.message);
        } finally {
            setIsLoading(dispatch, false);
            let recentSearches = getRecentSearches(queryType);

            if (recentSearches) {
                if (recentSearches.length > 4) recentSearches.pop();
                if (recentSearches.indexOf(query) === -1) recentSearches.unshift(query);
            } else {
                recentSearches = [query];
            }
            setRecentSearches(queryType, recentSearches);
        }
    };

    if (enterPressed) {
        handleSubmit();
        setEnterPressed(dispatch, false);
    }

    const getPlaceholder = () => {
        switch (queryType) {
            case 'USERNAME':
                return "ex. 'john.smith'";
            case 'EMAIL':
                return "ex. 'john.smith@email.com'";
        }
    };

    const getQueryTypeHelper = () => {
        switch (queryType) {
            case 'USERNAME':
            case 'EMAIL':
                return `${queryType} (lowercase)`;
        }
    };

    return (
        <>
            <Header context="User Functions" title="Get Login Activity Log" />
            <FloatingButton
                onClick={() => {
                    if (topRef && topRef.current) {
                        topRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                        });
                    }
                }}
            />
            <div className="l-container" ref={topRef}>
                <form onSubmit={handleSubmit}>
                    <div className="c-field">
                        <label htmlFor="queryType" className="c-field__label">
                            Query Type
                        </label>
                        <div className="c-select">
                            <select
                                onChange={handleChangeQueryType}
                                id="queryType"
                                defaultValue="USERNAME"
                            >
                                {['USERNAME', 'EMAIL'].map((type, i) => {
                                    return <option key={i}>{type}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={`c-field ${queryError && 'u-margin-bottom-none'}`}>
                        <label
                            htmlFor="query"
                            className="c-field__label u-margin-top-large"
                        >
                            {getQueryTypeHelper()}
                        </label>
                        <FieldWithHistory
                            fieldId="query"
                            searchKey={queryType}
                            selected={queryText}
                            onInputChange={(e) => {
                                if (e !== undefined) {
                                    if (['USERNAME', 'EMAIL'].includes(queryType)) {
                                        e = e.toLowerCase();
                                    }
                                    setQueryText(dispatch, e);
                                    handleChangeQuery(e);
                                }
                            }}
                            onChange={(e) => {
                                if (e !== undefined) {
                                    if (['USERNAME', 'EMAIL'].includes(queryType)) {
                                        e = e.toLowerCase();
                                    }
                                    setQueryText(dispatch, e);
                                    handleChangeQuery(e);
                                }
                            }}
                            placeholder={getPlaceholder()}
                            onEnter={() => {
                                setEnterPressed(dispatch, true);
                            }}
                        />
                        {queryError && (
                            <div className="u-text-error u-margin-left-large">
                                <small>{queryError}</small>
                            </div>
                        )}
                    </div>
                    <div className="c-field">
                        <label
                            htmlFor="query"
                            className="c-field__label u-margin-top-large"
                        >
                            Date Range
                        </label>
                        <CustomDatePicker
                            lang="en"
                            idPrefix="tool"
                            value={{
                                start: startDate,
                                end: endDate,
                                name: 'Last 30 Days',
                            }}
                            drops={'down'}
                            onChange={handleRangeChange}
                            user={props.user}
                        />
                    </div>

                    <div className="c-field">
                        <LoaderButton
                            type="submit"
                            disabled={!validateForm()}
                            isLoading={isLoading}
                            text="Get Log"
                            loadingText="Searching..."
                        />
                    </div>
                </form>
            </div>
            <FadeIn visible={!isLoading && showLog}>
                <CopyData info={pageData}/>
                <div className="u-text-center">
                    {queriedUser && (
                        <>
                            <h2>
                                Login Table For User: {queriedUser.userName}
                            </h2>
                        </>
                    )}
                </div>
                {(
                    <>
                        <GenericTable
                            id="LOGIN_ACTIVITY-table"
                            data={log}
                            filterKeys={allFilterKeys}
                            columnDefs={baseLoginActivityLogColumns.slice(1)}
                            defaultSorted={[
                                {
                                    id: 'actionDate',
                                    desc: true,
                                },
                            ]}
                            savedColumnsId={'fullLogTableColumns'}
                        />
                    </>
                )}
            </FadeIn>
        </>
    );
};

export default GetLoginActivityLog;
