import React, { useEffect, useReducer, useState } from 'react';
import { initialState, reducer } from './store';
import FadeIn from 'react-fade-in';
import _ from 'underscore';
import moment from 'moment';

import FloatingButton from '../../../components/FloatingButton';
import { Header } from '../../../components/Header';
import LoaderButton from '../../../components/LoaderButton';
import CustomDatePicker from '../../../components/CustomDatePicker';
import GenericTable from '../../../components/GenericTable';
import CustomReactSelect from '../../../components/CustomReactSelect';
import Switch from '../../../components/Switch';
import { OptionType } from '../../../components/ReactSelect/Option';
import FieldWithHistory from '../../../components/FieldWithHistory';

import {
  getUserLog,
  getUserSummary,
  getOEMRegionList,
} from '../../../libs/db-lib';

import {
  usernameValidate,
  isValidEmail,
  uuidValidate,
  formatTimezone,
  updateLogData
} from '../../../libs/utils';
import {
  getRecentSearches,
  setRecentSearches,
  storeClipboard,
} from '../../../libs/utils-ts';

import {
  allFilterKeys,
  logTableStructure,
} from '../../../libs/tables/logTableStructure';
import { baseLogColumns } from '../../../libs/tables/columnDefinitions';
import { LogSubcomponent } from '../../../libs/tables/subComponents';

import { ChildProps, Log, QueriedUser, GenericObj } from '../../../types';

import {
  setLog,
  setGroupedLog,
  setQuery,
  setQueryText,
  setQueryType,
  setQueryError,
  setStartDate,
  setEndDate,
  setSelectedActionCodes,
  setIsLoading,
  setQueriedUser,
  setTabset,
  setShowLog,
  setShowUserIDCol,
  setShowActionCodeCol,
  setEnterPressed,
} from './store/actions';
import CopyData from '../../../components/CopyData';

let state = initialState;


const GetUserLog = (props: ChildProps) => {
  const [getUserLogStore, dispatch] = useReducer(reducer, state);

  const {
    log,
    groupedLog,
    queryText,
    queryType,
    queryError,
    startDate,
    endDate,
    selectedActionCodes,
    isLoading,
    queriedUser,
    showLog,
    showUserIDCol,
    showActionCodeCol,
    enterPressed,
  } = getUserLogStore;

  const [oemInfo, setOemInfo] = useState([]);

  let { query } = getUserLogStore;

  const topRef = React.createRef<HTMLDivElement>();

  let pageData: { [k: string]: any } = {
    'Query Type': queryType,
    Query: query,
    'Date Range': `${startDate} to ${endDate}`,
    'User Log Count': log.length,
    'User Log': log,
  };

  storeClipboard(pageData);
  state = { ...getUserLogStore, isLoading: false };

  useEffect(() => {
    const getOemsList = async () => {
      const oemRegions = await getOEMRegionList();
      const oemRegionsInfo: any = {};
      oemRegions.forEach((oem: any) => {
        oemRegionsInfo[oem.oemID] = oem.oemName;
      });
      setOemInfo(oemRegionsInfo);
    }
    getOemsList();
  }, []);

  useEffect(() => {
    const updateAndGroupLogs = async () => {
      await updateLogData(log, oemInfo);
      let groups = log.reduce((group: any, entry: any) => {
        group[entry.actionCode] = group[entry.actionCode] || [];
        group[entry.actionCode].push(entry);
        return group;
      }, Object.create(null));
      setGroupedLog(dispatch, groups);
    }
    updateAndGroupLogs();
  }, [log]);

  const handleChangeQuery = _.debounce((query: string) => {
    let error = '';
    switch (queryType) {
      case 'USERNAME':
        error = !usernameValidate(query.trim())
          ? 'Username must be at least 8 alphanumeric lowercase characters starting with a letter'
          : '';
        query = query.toLowerCase();
        break;
      case 'EMAIL':
        error = !isValidEmail(query.trim()) ? 'Email format is not valid!' : '';
        query = query.toLowerCase();
        break;
      case 'USER ID':
        error = !uuidValidate(query.trim())
          ? 'User ID must be in this format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
          : '';
        query = query.toLowerCase();
        break;
    }
    setQueryError(dispatch, error);
    setQuery(dispatch, query);
  }, 300);

  const handleChangeQueryType = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setQueryType(dispatch, event.target.value);
    setQueryError(dispatch, '');
    setQueryText(dispatch, '');
  };

  const handleRangeChange = (value: any) => {
    setStartDate(dispatch, value.start);
    setEndDate(dispatch, value.end);
  };

  const validateForm = () => {
    switch (queryType) {
      case 'USERNAME':
        return usernameValidate(query.trim());
      case 'EMAIL':
        return isValidEmail(query.trim());
      case 'USER ID':
        return uuidValidate(query.trim());
      default:
        return query.trim().length > 0;
    }
  };

  const handleSubmit = async (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    setShowLog(dispatch, false);
    setIsLoading(dispatch, true);
    setLog(dispatch, []);
    setSelectedActionCodes(dispatch, []);
    setGroupedLog(dispatch, {} as Log);
    setQueriedUser(dispatch, {} as QueriedUser);

    const startDateStr = moment(startDate).startOf('day').utc().toISOString();
    // If the end date is the current date, make the end date the current moment for most up-to-date logs
    const endDateStr = moment(endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? moment().utc().toISOString()
      : moment(endDate).endOf('day').utc().toISOString();

    try {
      let result:any = null;
      if (queryType === 'USERNAME') {
        query = query.toLowerCase();
      }

      if (queryType !== 'USER ID') {
        let user = await getUserSummary(queryType, query);
        if (user.error === 'User record not found!') {
          props.handleShowAlert(
            'Error',
            'This user was not found, or does not exist!'
          );
          return;
        }
        setQueriedUser(dispatch, user);
        result = await getUserLog(user.userID, startDateStr, endDateStr);
      } else {
        setQueriedUser(dispatch, {
          userID: query,
          userName: '',
        } as QueriedUser);
        result  = await getUserLog(query, startDateStr, endDateStr);
      }

      if (!result || Object.hasOwn(result, 'error')) {
        if (result && [401, 403].indexOf(result.error?.status) === -1) {
          props.handleShowAlert('Error', result.error);
        } else {
          props.handleShowAlert('Unknown Error getting user log', '');
        }
        return;
      } else if (result.length === 0) {
        props.handleShowAlert(
          'Get Logs',
          <span>
            No logs found for query: <strong>{query}</strong> in that date
            range.
          </span>,
          false
        );
      } else {
        setLog(dispatch, result);
        setShowLog(dispatch, true);
      }
    } catch (e: any) {
      props.handleShowAlert(
        'Get Logs',
        <span>
          No logs found for query: <strong>{query}</strong> in that date range.
        </span>,
        false
      );
      console.log(e.message);
    } finally {
      setIsLoading(dispatch, false);

      let recentSearches = getRecentSearches(queryType);

      if (recentSearches) {
        if (recentSearches.length > 4) recentSearches.pop();
        if (recentSearches.indexOf(query) === -1) recentSearches.unshift(query);
      } else {
        recentSearches = [query];
      }
      setRecentSearches(queryType, recentSearches);
    }
  };

  if (enterPressed) {
    handleSubmit();
    setEnterPressed(dispatch, false);
  }

  const getPlaceholder = () => {
    switch (queryType) {
      case 'USERNAME':
        return "ex. 'john.smith'";
      case 'EMAIL':
        return "ex. 'john.smith@email.com'";
      case 'USER ID':
        return "ex. 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'";
    }
  };

  const getQueryTypeHelper = () => {
    switch (queryType) {
      case 'USERNAME':
      case 'EMAIL':
      case 'USER ID':
        return `${queryType} (lowercase)`;
    }
  };

  return (
    <>
      <Header context="User Functions" title="Get User Log" />
      <FloatingButton
        onClick={() => {
          if (topRef && topRef.current) {
            topRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }}
      />
      <div className="l-container" ref={topRef as any}>
        <form onSubmit={handleSubmit}>
          <div className="c-field">
            <label htmlFor="queryType" className="c-field__label">
              Query Type
            </label>
            <div className="c-select">
              <select
                onChange={handleChangeQueryType}
                id="queryType"
                defaultValue="USERNAME"
              >
                {['USERNAME', 'EMAIL', 'USER ID'].map((type, i) => {
                  return <option key={i}>{type}</option>;
                })}
              </select>
            </div>
          </div>
          <div className={`c-field ${queryError && 'u-margin-bottom-none'}`}>
            <label
              htmlFor="query"
              className="c-field__label u-margin-top-large"
            >
              {getQueryTypeHelper()}
            </label>
            <FieldWithHistory
              fieldId="query"
              searchKey={queryType}
              selected={queryText}
              onInputChange={(e) => {
                if (e !== undefined) {
                  if (['USERNAME', 'EMAIL', 'USER ID'].includes(queryType)) {
                    e = e.toLowerCase();
                  }
                  setQueryText(dispatch, e);
                  handleChangeQuery(e);
                }
              }}
              onChange={(e) => {
                if (e !== undefined) {
                  if (['USERNAME', 'EMAIL', 'USER ID'].includes(queryType)) {
                    e = e.toLowerCase();
                  }
                  setQueryText(dispatch, e);
                  handleChangeQuery(e);
                }
              }}
              placeholder={getPlaceholder()}
              onEnter={() => {
                setEnterPressed(dispatch, true);
              }}
            />
            {queryError && (
              <div className="u-text-error u-margin-left-large">
                <small>{queryError}</small>
              </div>
            )}
          </div>
          <div className="c-field">
            <label
              htmlFor="query"
              className="c-field__label u-margin-top-large"
            >
              Date Range
            </label>
            <CustomDatePicker
              lang="en"
              value={{
                start: startDate,
                end: endDate,
                name: 'Last 30 Days',
              }}
              drops={'down'}
              onChange={handleRangeChange}
              user={props.user}
            />
          </div>

          <div className="c-field">
            <LoaderButton
              type="submit"
              disabled={!validateForm()}
              isLoading={isLoading}
              text="Get Log"
              loadingText="Searching..."
            />
          </div>
        </form>
      </div>
      {showLog && !isLoading && (
        <>
          <CopyData info={pageData}/>
          <FadeIn visible={!isLoading && showLog}>
            <div className="u-text-center">
              {queriedUser && (
                <>
                  <h2>
                    Log Table For User:{' '}
                    <span className="u-font-mono">{`${queriedUser.userName} (${queriedUser.userID})`}</span>
                  </h2>
                </>
              )}
            </div>
            <GenericTable
              id="USER_LOG-table"
              data={log || []}
              filterKeys={allFilterKeys}
              columnDefs={baseLogColumns.slice(1)}
              defaultSorted={[
                {
                  id: 'actionDate',
                  desc: true,
                },
              ]}
              savedColumnsId={'fullLogTableColumns'}
              subComponentRenderer={LogSubcomponent}
              getTrProps={(state: any, rowInfo: any, column: any) => {
                return {};
              }}
            />
          </FadeIn>
        </>
      )}
    </>
  );
};

export default GetUserLog;
