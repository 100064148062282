import Dialog from '@reach/dialog';
import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import _ from 'underscore';

import AlertModal from '../../components/AlertModal';
import CopyLink from '../../components/CopyLink';
import GenericPopover from '../../components/GenericPopover';
import GenericTable from '../../components/GenericTable';
import { Header } from '../../components/Header';
import LoaderButton from '../../components/LoaderButton';
import { Loading } from '../../components/Loading';

import { UserContextInterface, useUser } from '../../context/User';
import {
  getToolRecoveryRequests,
  refreshCache,
  processToolRecoveryRequest,
  getShopNames,
  contactToolRecoveryOwner,
  closeToolRecoveryRequest,
} from '../../libs/db-lib';
import { toolRecoveryRequestsColumns } from '../../libs/tables/columnDefinitions';
import { getSavedColumns } from '../../libs/utils-ts';
import { ChildProps } from '../../types';
import CustomDatePicker from '../../components/CustomDatePicker';

interface HomeProps extends ChildProps {
  history: any;
  userContext: UserContextInterface;
}

interface ToolRecoveryRequest {
  toolID: string;
  shopID: string;
  userID: string;
  actionDate: string;
  actionCode: string;
  actionMessage: string;
  fullstoryLink: string;
  toolSerial: string;
  toolManufacturer: string;
  toolModel: string;
  recoveryStatus: string;
  imageUrl: string;
  requesterTicketID: string;
  ownerTicketID: string;
}

interface HomeState {
  isLoading: boolean;
  showModal: boolean;
  windowWidth: number;
  alertTitle?: string;
  alertMessage?: string | JSX.Element;
  toolRecoveryRequests: ToolRecoveryRequest[];
  toolRecoveryShops: any;
  selectedRequest: any;
  recoveryRejectReason: string;
  otherReason: string;
  showRejectReasonModal: boolean;
  loadingActions: boolean;
  showClosed: boolean;
  startDate: string;
  endDate: string;
}

class Dashboard extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);

    this.state = {
      isLoading: false,
      showModal: false,
      windowWidth: window.innerWidth,
      toolRecoveryRequests: [],
      toolRecoveryShops: {} as { [k: string]: any },
      selectedRequest: {},
      recoveryRejectReason: '',
      otherReason: '',
      showRejectReasonModal: false,
      loadingActions: false,
      showClosed: false,
      startDate: moment.utc().subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: moment.utc().format('YYYY-MM-DD'),
    };
  }

  // componentDidUpdate() {}

  async componentDidMount() {
    window.addEventListener('resize', this.setWindowWidth.bind(this));
    if (['ADMIN', 'MANAGER'].includes(this.props.user.userType)) {
      this.handleRefresh();
    }
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  handleRefresh = _.throttle(async () => {
    this.setState({
      isLoading: true,
    });

    const startDateStr = moment(this.state.startDate).startOf('day').utc().toISOString();
    // If the end date is the current date, make the end date the current moment for most up-to-date logs
    const endDateStr = moment(this.state.endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? moment().utc().toISOString()
      : moment(this.state.endDate).endOf('day').utc().toISOString();

    refreshCache();
    let toolRecoveryRequests = await getToolRecoveryRequests(this.state.showClosed, startDateStr, endDateStr);
    if (Object.hasOwn(toolRecoveryRequests, 'error')) {
      if ([401, 403].indexOf(toolRecoveryRequests.error.status) === -1) {
        this.setState({
          isLoading: false,
        });
        this.props.handleShowAlert(
          'Error handling request',
          toolRecoveryRequests.error
        );
        return;
      }
    }

    if (
      !toolRecoveryRequests ||
      !Array.isArray(toolRecoveryRequests)
    ) {
      this.setState({
        isLoading: false,
      });
      return;
    }
    let shopIDs: string[] = [];

    toolRecoveryRequests.forEach((request: any) => {
      if (request.shopID) {
        shopIDs.push(request.shopID);
      }
      if (request.ownerShopID) {
        shopIDs.push(request.ownerShopID);
      }
    });

    shopIDs = _.uniq(shopIDs);
    let newShops = {};
    if (shopIDs.length > 0) {
      newShops = await getShopNames(shopIDs);
    }

    this.setState((prevState) => ({
      toolRecoveryShops: {
        ...prevState.toolRecoveryShops,
        ...newShops,
      },
    }));

    this.setState({
      toolRecoveryRequests: toolRecoveryRequests,
      isLoading: false,
    });
  }, 2000);

  handleRadioChange = (e: any) => {
    this.setState({
      recoveryRejectReason: e.target.value,
      otherReason: '',
    });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (this.state.recoveryRejectReason === 'other') {
      this.setState({
        otherReason: e.target.value,
      });
    }
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleCancelRejectRecovery = () => {
    document.querySelector('.c-modal')?.classList.add('closed');
    let self = this;
    setTimeout(() => {
      self.setState({ showRejectReasonModal: false });
    }, 350);
  };

  handleContactOwner = async (selectedRequest: any) => {
    const {
      toolID,
    } = selectedRequest;

    this.setState({
      loadingActions: selectedRequest.toolID,
    });

    let results = await contactToolRecoveryOwner(
      toolID,
    );

    await this.handleRefresh();

    let modalTitle = "Success!";
    if (results !== true) {
      modalTitle = 'Error handling request';
    }
    let modalMsg =
      results === true ? (
        <p>
          Owner has been contacted, and a Zendesk ticket has been opened for
          them. View it by clicking on the ticket{' '}
          <span className="c-btn__icon fa fa-ticket" title="Zendesk Ticket" />{' '}
          icon under the Zendesk column for this request.
        </p>
      ) : (
        (Object.hasOwn(results, 'error') && [401, 403].indexOf(results.error.status) === -1) ?
          <p>{results.error}</p>
        :
          <p>{results}</p>
      );
    this.props.handleShowAlert(modalTitle, modalMsg, false);
    this.setState({
      loadingActions: false,
    });
    return;
  };

  handleProcessRequest = async (selectedRequest: any, approved: boolean) => {
    if (!['ADMIN', 'MANAGER'].includes(this.props.user.userType)) {
      return;
    }
    const {
      toolID,
    } = selectedRequest;

    this.setState({
      loadingActions: toolID,
      isLoading: true,
    });

    if (approved) {
      let result = await processToolRecoveryRequest(
        toolID,
        'APPROVED',
      );

      await this.handleRefresh();

      this.setState({
        loadingActions: false,
      });

      if (Object.hasOwn(result, 'error')) {
        if ([401, 403].indexOf(result.error.status) === -1) {
          this.props.handleShowAlert('Error handling request', result.error);
          this.setState({
            loadingActions: false,
            isLoading: false,
          });
        }
        return;
      }
      let message =
        result.message === 'Tool not removed' ? (
          'Tool was previously removed and is now available to add.  Tickets closed.'
        ) : result.message === 'Tool is owned by a different shop' ? (
          <p>
            Tool was previously removed but is now owned by another shop.
            <CopyLink
              content={result.toolOwnership.shopID}
              type={'SHOP ID'}
              redirect={'/userFunctions/getShopSummary'}
              urlParams={`?query=${result.toolOwnership.shopID}&queryType=SHOP+ID`}
              action="search"
            />{' '}
            Tickets closed.
          </p>
        ) : (
          'Tool has been removed from this shop!'
        );

      this.props.handleShowAlert('Success!', message, false);
    } else {
      let result = await processToolRecoveryRequest(
        toolID,
        'REJECTED',
      );

      if (Object.hasOwn(result, 'error')) {
        if ([401, 403].indexOf(result.error.status) === -1) {
          this.props.handleShowAlert('Error handling request', result.error);
          this.setState({
            loadingActions: false,
            isLoading: false,
          });
        }
        return;
      }
      this.props.handleShowAlert(
        'Success!',
        'Tool recovery request has been rejected!',
        false
      );
    }

    let newRequests = this.state.toolRecoveryRequests.filter(
      (request) => request.toolID !== toolID
    );

    this.setState({
      toolRecoveryRequests: newRequests,
      loadingActions: false,
      isLoading: false,
    });
  };

  handleRangeChange = (value: any) => {
    this.setState({
      startDate: value.start,
      endDate: value.end,
    });
  };

  handleCloseRequest = async (selectedRequest: any) => {
    if (!['ADMIN', 'MANAGER'].includes(this.props.user.userType)) {
      return;
    }
    const {
      toolID,
    } = selectedRequest;

    this.setState({
      loadingActions: toolID,
      isLoading: true,
    });

    let reason =
      this.state.recoveryRejectReason === 'other'
        ? this.state.otherReason
        : this.state.recoveryRejectReason;

    let result = await closeToolRecoveryRequest(
      toolID,
      reason
    );

    if (Object.hasOwn(result, 'error')) {
      if ([401, 403].indexOf(result.error.status) === -1) {
        this.props.handleShowAlert('Error handling request', result.error);
        this.setState({
          loadingActions: false,
          isLoading: false,
        });
      }
      return;
    }
    this.props.handleShowAlert(
      'Success!',
      'Tool recovery request has been rejected, and ticket has been closed!',
      false
    );

    let newRequests = this.state.toolRecoveryRequests.filter(
      (request) => request.toolID !== toolID
    );

    this.setState({
      toolRecoveryRequests: newRequests,
      loadingActions: false,
      isLoading: false,
      showRejectReasonModal: false,
    });
  };

  renderLander() {
    if (!this.props.user) {
      return <Loading />;
    }

    const {
      toolRecoveryRequests,
      toolRecoveryShops,
      loadingActions,
      isLoading,
      alertTitle,
      alertMessage,
      selectedRequest,
      recoveryRejectReason,
      otherReason,
      showModal,
      showRejectReasonModal,
    } = this.state;

    return (
      <main>
        <Header title="Dashboard" />

        {['ADMIN', 'MANAGER'].includes(this.props.user.userType) && (
          <section className="c-card-raised">

            <div className="l-flex-between l-flex-align-center">
              <h2 className="c-card__title">Tool Recovery Requests</h2>
              <div className='l-flex-align-center'>
                <div className="c-btn__inner l-margin-right-10">Show Closed</div>
                <input
                  id="showClosed"
                  type="checkbox"
                  name="showClosed"
                  checked={this.state.showClosed}
                  onChange={()=>this.setState({ showClosed: !this.state.showClosed })}
                  className="c-btn__inner l-margin-right-20"
                />
                <button
                  className="c-btn-icon"
                  onClick={() => {
                    refreshCache();
                    this.handleRefresh();
                  }}
                >
                  <div className="c-btn__inner">
                    <span className="c-btn__icon fa fa-refresh" title="Approve" />
                  </div>
                </button>
              </div>
            </div>

            <hr />
            <div className="c-field">
              <label
                htmlFor="query"
                className="c-field__label u-margin-top-large"
              >
                Date Range
              </label>
              <CustomDatePicker
                lang="en"
                idPrefix="tool"
                value={{
                  start: this.state.startDate,
                  end: this.state.endDate,
                  name: 'Last 30 Days',
                }}
                drops={'down'}
                onChange={this.handleRangeChange}
                user={this.props.user}
              />
            </div>
            <GenericTable
              data={toolRecoveryRequests || []}
              filterKeys={[
                'actionMessage',
                'actionCode',
                'actionDate',
                'toolModel',
                'toolManufacturer',
                'toolSerial',
              ]}
              columnDefs={[
                ...toolRecoveryRequestsColumns.slice(0, 2),
                {
                  Header: 'Requester Shop',
                  accessor: 'shopID',
                  Cell: (row: any) => {
                    return (
                      <CopyLink
                        content={row.original.shopID}
                        sub={toolRecoveryShops[row.original.shopID]}
                        className="u-font-mono"
                        type="SHOP ID"
                        redirect="/shopFunctions/getShopSummary"
                        showCopy={false}
                        urlParams={`?query=${row.original.shopID}&queryType=SHOP+ID`}
                      />
                    );
                  },
                },
                {
                  Header: 'Tool Owner Shop',
                  accessor: 'ownerShopID',
                  Cell: (row: any) => {
                    if (!row.original.ownerShopID) return null;
                    return (
                      row.original.ownerShopID && (
                        <div>
                          <CopyLink
                            content={row.original.ownerShopID}
                            sub={toolRecoveryShops[row.original.ownerShopID]}
                            className="u-font-mono"
                            type="SHOP ID"
                            redirect="/shopFunctions/getShopSummary"
                            showCopy={false}
                            urlParams={`?query=${row.original.ownerShopID}&queryType=SHOP+ID`}
                          />
                          {
                            row.original.isEnterprise ?
                              <span className="u-text-warning">(Enterprise Shop)</span>
                            : row.original.ownerShopWeLostDispute ?
                              <span className="u-text-warning">(Lost Dispute)</span>
                            : ''
                          }
                        </div>
                      )
                    );
                  },
                },
                ...toolRecoveryRequestsColumns.slice(2),
                {
                  Header: 'Actions',
                  accessor: 'userID',
                  minWidth:
                    getSavedColumns('toolRecoveryRequestsColumns').userID ||
                    200,
                  maxWidth: 200,
                  Cell: (row: { original: any }) => {
                    return row.original.status.includes('CLOSED') ? (
                      <div className="l-display-block">
                        <div>
                          { row.original.status }
                        </div>
                        <div>
                          { row.original.closeReason }
                        </div>
                      </div>

                    ) : row.original.status.includes("PENDING") ? (
                      <div className="l-inline-flex">
                        {loadingActions !== row.original.toolID ? (
                          <>
                            <GenericPopover
                              anchorElement={
                                <button className="c-btn-icon--green">
                                  <div className="c-btn__inner">
                                    <span
                                      className="c-btn__icon fa fa-check"
                                      title="Approve Request"
                                    />
                                  </div>
                                </button>
                              }
                              body={
                                <strong>
                                  Are you sure you want to approve this request?
                                </strong>
                              }
                              primaryBtnElement={
                                <LoaderButton
                                  className="c-btn-medium c-btn-success"
                                  disabled={false}
                                  text="Yes, approve it"
                                  loadingText="Approving..."
                                  icon={'fa fa-check'}
                                />
                              }
                              secondaryBtnElement={
                                <button className="c-btn-medium c-btn-outline">
                                  <div className="c-btn__inner">
                                    <i className="c-btn__icon fal fa-times" />
                                    No, not yet
                                  </div>
                                </button>
                              }
                              primaryAction={ async () => {
                                return this.handleProcessRequest(row.original, true);
                              }}
                              color="#0088cc"
                              position="top"
                              align="end"
                              arrowSize={5}
                              closeOnClick={false}
                            />

                            <GenericPopover
                              anchorElement={
                                <button className="c-btn-icon--red">
                                  <div className="c-btn__inner">
                                    <span
                                      className="c-btn__icon fa fa-times"
                                      title="Deny Request"
                                    />
                                  </div>
                                </button>
                              }
                              body={
                                <strong>
                                  Are you sure you want to deny this request?
                                </strong>
                              }
                              primaryBtnElement={
                                <LoaderButton
                                  className="c-btn-medium c-btn-error"
                                  disabled={false}
                                  text="Yes, deny it"
                                  loadingText="Denying..."
                                  icon={'fa fa-check'}
                                />
                              }
                              secondaryBtnElement={
                                <button className="c-btn-medium c-btn-outline">
                                  <div className="c-btn__inner">
                                    <i className="c-btn__icon fal fa-times" />
                                    No, not yet
                                  </div>
                                </button>
                              }
                              primaryAction={ async () => {
                                return this.handleProcessRequest(row.original, false);
                              }}
                              color="#0088cc"
                              position="top"
                              align="end"
                              arrowSize={5}
                              closeOnClick={false}
                            />
                          </>
                        ) : (
                          <button className="c-btn-icon">
                            <div className="c-btn__inner">
                              <i className="c-btn__icon fal fa-spinner-third spinning" />
                            </div>
                          </button>
                        )}
                      </div>
                    ) : loadingActions !== row.original.toolID ? (
                      <div className="l-inline-flex">
                        <GenericPopover
                          anchorElement={
                            <button className="c-btn-icon">
                              <div className="c-btn__inner">
                                <span
                                  className="c-btn__icon fa fa-envelope"
                                  title="Contact Owner"
                                />
                              </div>
                            </button>
                          }
                          body={
                            <strong>
                              Are you sure you want to open a ticket with this
                              tool's owner?
                            </strong>
                          }
                          primaryBtnElement={
                            <LoaderButton
                              className="c-btn-medium c-btn-success"
                              disabled={false}
                              text="Yes, open it"
                              loadingText="Opening..."
                              icon={'fa fa-check'}
                            />
                          }
                          secondaryBtnElement={
                            <button className="c-btn-medium c-btn-outline">
                              <div className="c-btn__inner">
                                <i className="c-btn__icon fal fa-times" />
                                No, not yet
                              </div>
                            </button>
                          }
                          primaryAction={ async () => {
                            return this.handleContactOwner(row.original);
                          }}
                          color="#0088cc"
                          position="top"
                          align="end"
                          arrowSize={5}
                          closeOnClick={false}
                        />

                        <GenericPopover
                          anchorElement={
                            <button className="c-btn-icon">
                              <div className="c-btn__inner">
                                <span
                                  className="c-btn__icon fa fa-trash-alt"
                                  title="Close Request"
                                />
                              </div>
                            </button>
                          }
                          body={
                            <strong>
                              Are you sure you want to close this request?
                            </strong>
                          }
                          primaryBtnElement={
                            <LoaderButton
                              className="c-btn-medium c-btn-success"
                              disabled={false}
                              text="Yes, close it"
                              loadingText="Closing..."
                              icon={'fa fa-check'}
                            />
                          }
                          secondaryBtnElement={
                            <button className="c-btn-medium c-btn-outline">
                              <div className="c-btn__inner">
                                <i className="c-btn__icon fal fa-times" />
                                No, not yet
                              </div>
                            </button>
                          }
                          primaryAction={ async () => {
                            return this.setState({
                              selectedRequest: row.original,
                              showRejectReasonModal: true,
                            });
                          }}
                          color="#0088cc"
                          position="top"
                          align="end"
                          arrowSize={5}
                          closeOnClick={false}
                        />
                      </div>
                    ) : (
                      <button className="c-btn-icon">
                        <div className="c-btn__inner">
                          <i className="c-btn__icon fal fa-spinner-third spinning" />
                        </div>
                      </button>
                    );
                  },
                },
              ]}
              defaultSorted={[
                {
                  id: 'actionDate',
                  desc: true,
                },
              ]}
              loadingText={'Loading requests...'}
              loading={isLoading}
              savedColumnsId={'toolRecoveryRequestsColumns'}
            />
          </section>
        )}

        <AlertModal
          title={alertTitle}
          message={alertMessage}
          showModal={showModal}
          handleCancel={this.handleCancel.bind(this)}
        />

        {/* Tool Recovery Reject Reason */}
        <Dialog
          isOpen={showRejectReasonModal}
          onDismiss={this.handleCancelRejectRecovery}
          className="c-modal"
          aria-label={'Confirm Reject Recovery'}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelRejectRecovery}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">Confirm Recovery Rejection</h1>

          <div className="c-modal__body">
            <p>
              Please specify a reason for rejecting this recovery. Note that if
              you type in an "Other" reason, the requester will be able to see
              your response in the ticket. If no reason is specified, the
              default rejection message will be sent to the requester.
            </p>
            <div className="c-field">
              <label className="c-field__label">Select a reason</label>
              <div onChange={this.handleRadioChange}>
                <div className="l-flex">
                  <input
                    type="radio"
                    id="radioGroup"
                    name="radioGroup"
                    value="wrongToolSerialNumber"
                  />
                  <p style={{ marginBottom: 'revert' }}>
                    Wrong tool serial number
                  </p>
                </div>

                <br />
                <div className="l-flex">
                  <input
                    type="radio"
                    id="radioGroup"
                    name="radioGroup"
                    value="toolInCorrectShop"
                  />
                  <p style={{ marginBottom: 'revert' }}>
                    Tool is already in the correct shop
                  </p>
                </div>

                <br />
                <div className="l-flex">
                  <input
                    type="radio"
                    id="radioGroup"
                    name="radioGroup"
                    value="requestMistake"
                  />
                  <p style={{ marginBottom: 'revert' }}>
                    Request made by mistake
                  </p>
                </div>

                <br />
                <div className="l-flex">
                  <input
                    type="radio"
                    id="radioGroup"
                    name="radioGroup"
                    value="maliciousRequest"
                  />
                  <p style={{ marginBottom: 'revert' }}>
                    Redundant or malicious request
                  </p>
                </div>

                <br />

                <div className="l-flex">
                  <input
                    type="radio"
                    id="radioGroup"
                    name="radioGroup"
                    value="other"
                  />
                  <input
                    style={{ width: '97%' }}
                    autoFocus
                    id="otherReason"
                    name="otherReason"
                    maxLength={500}
                    type="text"
                    className="c-input"
                    placeholder={'Other reason'}
                    value={otherReason}
                    disabled={recoveryRejectReason !== 'other'}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="l-flex-end">
              <button
                className="c-btn-outline"
                onClick={() => {
                  this.setState({
                    recoveryRejectReason: '',
                  });
                  this.handleCancelRejectRecovery();
                }}
              >
                Cancel
              </button>
              <LoaderButton
                onClick={() => {
                  this.handleCloseRequest(selectedRequest);
                }}
                className="c-btn c-btn-error u-margin-left"
                disabled={false}
                isLoading={isLoading}
                text="Close Request"
                loadingText="Closing..."
                icon={'fa fa-trash-alt'}
              />
            </div>
          </div>
        </Dialog>
      </main>
    );
  }

  render() {
    return <>{this.renderLander()}</>;
  }
}

export default (props: ChildProps) => {
  const userContext = useUser();
  return <Dashboard userContext={userContext} {...props} />;
};
