import React, { useEffect, useReducer, useState } from 'react';
import { initialState, reducer } from './store';
import FadeIn from 'react-fade-in';
import _ from 'underscore';
import moment from 'moment';

import FloatingButton from '../../../components/FloatingButton';
import { Header } from '../../../components/Header';
import HelpTooltip from '../../../components/Tooltips/HelpTooltip';
import LoaderButton from '../../../components/LoaderButton';
import CustomDatePicker from '../../../components/CustomDatePicker';
import GenericTable from '../../../components/GenericTable';
import CustomReactSelect from '../../../components/CustomReactSelect';
import Switch from '../../../components/Switch';
import FieldWithHistory from '../../../components/FieldWithHistory';
import { OptionType } from '../../../components/ReactSelect/Option';

import {
  getShopLog,
  getUserSummary,
  getShopSummaryByProperty,
  getOEMRegionList,
} from '../../../libs/db-lib';

import {
  allFilterKeys,
  logTableStructure,
} from '../../../libs/tables/logTableStructure';
import {
  formatTimezone,
  usernameValidate,
  uuidValidate,
  updateLogData
} from '../../../libs/utils';
import {
  getRecentSearches,
  setRecentSearches,
  storeClipboard,
} from '../../../libs/utils-ts';

import { baseLogColumns } from '../../../libs/tables/columnDefinitions';
import { LogSubcomponent } from '../../../libs/tables/subComponents';

import { ChildProps, Log, Shop, GenericObj } from '../../../types';

import {
  setLog,
  setGroupedLog,
  setQuery,
  setQueryText,
  setQueryType,
  setQueryError,
  setHelpText,
  setStartDate,
  setEndDate,
  setSelectedActionCodes,
  setIsLoading,
  setQueriedShop,
  setTabset,
  setShowLog,
  setShowSelectShop,
  setShops,
  setShowShopIDCol,
  setShowActionCodeCol,
  setEnterPressed,
} from './store/actions';
import CopyData from '../../../components/CopyData';

let state = initialState;


const GetShopLog = (props: ChildProps) => {
  const [getShopLogStore, dispatch] = useReducer(reducer, state);
  const {
    log,
    groupedLog,
    queryText,
    queryType,
    queryError,
    helpText,
    startDate,
    endDate,
    selectedActionCodes,
    isLoading,
    queriedShop,
    showLog,
    showSelectShop,
    shops,
    showShopIDCol,
    showActionCodeCol,
    enterPressed,
  } = getShopLogStore;

  const [oemInfo, setOemInfo] = useState([]);

  let { query } = getShopLogStore;

  const topRef = React.createRef<HTMLDivElement>();

  state = { ...getShopLogStore, isLoading: false };

  let pageData: { [k: string]: any } = {
    'Query Type': queryType,
    Query: query,
    'Date Range': `${startDate} to ${endDate}`,
    'Shop Log Count': log.length,
    'Shop Log': log,
  };

  storeClipboard(pageData);

  useEffect(() => {
    const getOemsList = async () => {
      const oemRegions = await getOEMRegionList();
      const oemRegionsInfo: any = {};
      oemRegions.forEach((oem: any) => {
        oemRegionsInfo[oem.oemID] = oem.oemName;
      });
      setOemInfo(oemRegionsInfo);
    }
    getOemsList();
  }, []);

  useEffect(() => {
    const updateAndGroupLogs = async () => {
      await updateLogData(log, oemInfo);
      let groups = log.reduce((group: any, entry: any) => {
        group[entry.actionCode] = group[entry.actionCode] || [];
        group[entry.actionCode].push(entry);
        return group;
      }, Object.create(null));
      setGroupedLog(dispatch, groups);
    }
    updateAndGroupLogs();
  }, [log]);

  const handleChangeQuery = _.debounce((query: string) => {
    let error = '';
    switch (queryType) {
      case 'SHOP ID':
      case 'OWNER ID':
        error = !uuidValidate(query.trim())
          ? 'ID must be in this format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
          : '';
        query = query.toLowerCase();
        break;
      case 'OWNER USERNAME':
        error = !usernameValidate(query.trim())
          ? 'Username must be at least 8 alphanumeric lowercase characters starting with a letter'
          : '';
        query = query.toLowerCase();
        break;
    }
    setQueryError(dispatch, error);
    setQuery(dispatch, query);
  }, 300);

  const handleChangeQueryType = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    let helpText = '' as string | JSX.Element;
    switch (event.target.value) {
      case 'SHOP NAME':
        helpText =
          'SHOP NAME is a case-sensitive "contains" query, and can be partially matched (i.e., "T", "Te", "Tes" or "Test" will match "Test Auto Shop").';
        break;
    }

    setHelpText(dispatch, helpText);
    setQueryType(dispatch, event.target.value);
    setQueryError(dispatch, '');
    query = ''
    pageData.Query = ''
    setQueryText(dispatch, '');
  };

  const handleRangeChange = (value: any) => {
    setStartDate(dispatch, value.start);
    setEndDate(dispatch, value.end);
  };

  const validateForm = () => {
    switch (queryType) {
      case 'SHOP ID':
      case 'OWNER ID':
        return uuidValidate(query.trim());
      case 'OWNER USERNAME':
        return usernameValidate(query.trim());
      default:
        return query.trim().length > 0;
    }
  };

  const handleSubmit = async (
    e?: React.SyntheticEvent,
    queryBySelectShop?: string,
    queryTypeBySelectShop?: string,
    shopName?: string
  ) => {
    e?.preventDefault();
    setShowLog(dispatch, false);
    setShowSelectShop(dispatch, false);
    setShops(dispatch, []);
    setIsLoading(dispatch, true);
    setLog(dispatch, []);
    setSelectedActionCodes(dispatch, []);
    setGroupedLog(dispatch, {} as Log);
    setQueriedShop(dispatch, {} as Shop);

    const startDateStr = moment(startDate).startOf('day').utc().toISOString();
    // If the end date is the current date, make the end date the current moment for most up-to-date logs
    const endDateStr = moment(endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ? moment().utc().toISOString()
    : moment(endDate).endOf('day').utc().toISOString();

    try {
      let result: any = null;
      if (queryBySelectShop && queryTypeBySelectShop) {
        setQueriedShop(dispatch, {
          shopID: queryBySelectShop,
          shopName: shopName,
        } as Shop);
        result = await getShopLog(queryBySelectShop, startDateStr, endDateStr);
      } else if (queryType === 'SHOP NAME') {
        let results = await getShopSummaryByProperty('SHOP NAME', query);
        if (results.length > 0) {
          if (results.length === 1) {
            result = await getShopLog(
              results[0].shopID,
              startDateStr,
              endDateStr
            );
            setQueriedShop(dispatch, {
              shopID: results[0].shopID,
              shopName: results[0].shopName,
            } as Shop);
          } else {
            setShowSelectShop(dispatch, true);
            setShops(dispatch, results);
            setIsLoading(dispatch, false);
            return;
          }
        } else {
          props.handleShowAlert(
            'Get Logs',
            <span>
              No logs found for query: <strong>{query}</strong> in that date
              range.
            </span>,
            false
          );
        }
      } else if (queryType === 'OWNER ID') {
        let user = await getUserSummary('USERID', query);
        if (user.error === 'User record not found!') {
          props.handleShowAlert(
            'Error',
            'This user was not found, or does not exist!'
          );
          return;
        }
        let shop = user.userShops.find(
          (shop: Shop) => shop.shopUserRole === 'OWNER'
        );
        if (shop) {
          setQueriedShop(dispatch, {
            shopID: shop.shopID,
            shopName: shop.shopName,
          } as Shop);
          result = await getShopLog(shop.shopID, startDateStr, endDateStr);
        } else {
          props.handleShowAlert(
            'Error',
            'This user is not the OWNER of any shops.'
          );
          return;
        }
      } else if (queryType === 'OWNER USERNAME') {
        query = query.toLowerCase();
        let user = await getUserSummary('USERNAME', query);
        if (user.error === 'User record not found!') {
          props.handleShowAlert(
            'Error',
            'This user was not found, or does not exist!'
          );
          return;
        }
        let shop = user.userShops.find(
          (shop: Shop) => shop.shopUserRole === 'OWNER'
        );
        if (shop) {
          setQueriedShop(dispatch, {
            shopID: shop.shopID,
            shopName: shop.shopName,
          } as Shop);
          result = await getShopLog(shop.shopID, startDateStr, endDateStr);
        } else {
          props.handleShowAlert(
            'Error',
            'This user is not the OWNER of any shops.'
          );
          return;
        }
      } else {
        setQueriedShop(dispatch, { shopID: query, shopName: '' } as Shop);
        result = await getShopLog(query, startDateStr, endDateStr);
      }

      if (!result || Object.hasOwn(result, 'error')) {
        if (result && [401, 403].indexOf(result.error.status) === -1) {
          props.handleShowAlert('Error', result.error);
        } else {
          props.handleShowAlert('Unknown error occurred getting shop log', '');
        }
        return;
      } else if (result.logEntries.length === 0) {
        props.handleShowAlert(
          'Get Logs',
          <span>
            No logs found for query: <strong>{query}</strong> in that date
            range.
          </span>,
          false
        );
      } else {
        setLog(dispatch, result.logEntries);
        setShowLog(dispatch, true);
      }
    } catch (e: any) {
      if (!e || Object.hasOwn(e, 'error')) {
        if ([401, 403].indexOf(e.error.status) === -1) {
          props.handleShowAlert('Error', e.message);
        } else {
          return;
        }
      }
    } finally {
      setIsLoading(dispatch, false);
      let recentSearches = getRecentSearches(queryType);

      if (recentSearches) {
        if (recentSearches.length > 4) recentSearches.pop();
        if (recentSearches.indexOf(query) === -1) recentSearches.unshift(query);
      } else {
        recentSearches = [query];
      }
      setRecentSearches(queryType, recentSearches);
    }
  };

  if (enterPressed) {
    handleSubmit();
    setEnterPressed(dispatch, false);
  }

  const getPlaceholder = () => {
    switch (queryType) {
      case 'SHOP NAME':
        return "ex. 'John's Auto'";
      case 'SHOP ID':
      case 'OWNER ID':
        return "ex. 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'";
      case 'OWNER USERNAME':
        return "ex. 'john.smith'";
      default:
        return '';
    }
  };

  const getQueryTypeHelper = () => {
    switch (queryType) {
      case 'SHOP ID':
      case 'OWNER ID':
      case 'OWNER USERNAME':
        return `${queryType} (lowercase)`;
      case 'SHOP NAME':
        return queryType;
    }
  };

  return (
    <>
      <Header context="Shop Functions" title="Get Shop Log" />
      <FloatingButton
        onClick={() => {
          if (topRef && topRef.current) {
            topRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }}
      />
      <div className="l-container" ref={topRef}>
        <form onSubmit={handleSubmit}>
          <div className="c-field">
            <label htmlFor="queryType" className="c-field__label">
              Query Type
            </label>
            <div className="c-select">
              <select
                onChange={handleChangeQueryType}
                id="queryType"
                defaultValue="SHOP NAME"
              >
                {['SHOP NAME', 'SHOP ID', 'OWNER ID', 'OWNER USERNAME'].map(
                  (type, i) => {
                    return <option key={i}>{type}</option>;
                  }
                )}
              </select>
            </div>
          </div>
          <div className={`c-field ${queryError && 'u-margin-bottom-none'}`}>
            <label
              htmlFor="query"
              className="l-flex-align-center c-field__label u-margin-top-large"
            >
              {getQueryTypeHelper()}
              {helpText && (
                <small>
                  <HelpTooltip
                    label={helpText}
                    additionalStyles={{ fontSize: '12px' }}
                    onClick={() =>
                      props.handleShowAlert &&
                      props.handleShowAlert('Info', helpText, false)
                    }
                  />
                </small>
              )}
            </label>
            <FieldWithHistory
              fieldId="query"
              searchKey={queryType}
              selected={queryText}
              onInputChange={(e) => {
                if (e !== undefined) {
                  if (
                    ['SHOP ID', 'OWNER ID', 'OWNER USERNAME'].includes(
                      queryType
                    )
                  ) {
                    e = e.toLowerCase();
                  }
                  setQueryText(dispatch, e);
                  handleChangeQuery(e);
                }
              }}
              onChange={(e) => {
                if (e !== undefined) {
                  if (
                    ['SHOP ID', 'OWNER ID', 'OWNER USERNAME'].includes(
                      queryType
                    )
                  ) {
                    e = e.toLowerCase();
                  }
                  setQueryText(dispatch, e);
                  handleChangeQuery(e);
                }
              }}
              placeholder={getPlaceholder()}
              onEnter={() => {
                setEnterPressed(dispatch, true);
              }}
            />
            {queryError && (
              <div className="u-text-error u-margin-left-large">
                <small>{queryError}</small>
              </div>
            )}
          </div>
          <div className="c-field">
            <label
              htmlFor="query"
              className="c-field__label u-margin-top-large"
            >
              Date Range
            </label>
            <CustomDatePicker
              lang="en"
              idPrefix="tool"
              value={{
                start: startDate,
                end: endDate,
                name: 'Last 30 Days',
              }}
              drops={'down'}
              onChange={handleRangeChange}
              user={props.user}
            />
          </div>

          <div className="c-field">
            <LoaderButton
              type="submit"
              disabled={!validateForm()}
              isLoading={isLoading}
              text="Get Log"
              loadingText="Searching..."
            />
          </div>
        </form>
      </div>
      <FadeIn visible={!isLoading && showSelectShop}>
        <div>
          <h2>{shops.length} result(s) found:</h2>
        </div>
        {shops.map((shop: any) => {
          return (
            <div className="c-box" key={shop.shopID}>
              <div className="l-flex-wrap">
                <section className="c-section-alt l-flex-wrap">
                  <div className="c-section-alt__content">
                    <label className="c-section-alt__label">Shop</label>
                    <span className="c-section-alt__value u-font-mono">
                      {shop.shopName}
                    </span>
                  </div>
                  <div className="c-section-alt__content">
                    <label className="c-section-alt__label">ID</label>
                    <span className="c-section-alt__value u-font-mono">
                      {shop.shopID}
                    </span>
                  </div>
                  <div className="c-section-alt__content">
                    <label className="c-section-alt__label">Type</label>
                    <span className="c-section-alt__value">
                      {shop.shopType ? shop.shopType : 'NONE'}
                    </span>
                  </div>
                  <div className="c-section-alt__content">
                    <label className="c-section-alt__label">Owner</label>
                    <span className="c-section-alt__value u-font-mono">
                      {shop.shopOwner
                        ? `${shop.shopOwner.firstName} ${shop.shopOwner.lastName}`
                        : 'NONE'}
                    </span>
                  </div>
                </section>
                <button
                  className="c-btn"
                  onClick={() => {
                    handleSubmit(undefined, shop.shopID, 'SHOP ID',shop.shopName);
                  }}
                >
                  <div className="c-btn__inner">Get Log</div>
                </button>
              </div>
            </div>
          );
        })}
      </FadeIn>
      <FadeIn visible={!isLoading && showLog}>
        <div className="u-text-center">
          {queriedShop && (
            <>
              <CopyData info={pageData} />
              <h2>
                Log Table For Shop:{' '}
                <span className="u-font-mono">{`${queriedShop.shopName} (${queriedShop.shopID})`}</span>
              </h2>
            </>
          )}
        </div>
        <GenericTable
          id="SHOP_LOG-table"
          data={log || []}
          filterKeys={allFilterKeys}
          columnDefs={baseLogColumns}
          defaultSorted={[
            {
              id: 'actionDate',
              desc: true,
            },
          ]}
          savedColumnsId={'fullLogTableColumns'}
          subComponentRenderer={LogSubcomponent}
        />
      </FadeIn>
    </>
  );
};

export default GetShopLog;
