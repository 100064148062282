import { SupportUser, /*ShopName*/ } from '../types';
import { getUserRecord, getVinInfo, getAllShopNames } from './db-lib';
import { isMoment } from 'moment';

export function getSavedColumns(name: string): Record<string, number> {
  return JSON.parse(localStorage.getItem(name) || '[]').reduce(
    (acc: Record<string, string>, column: { id: string; value: number }) => ({
      ...acc,
      [column.id]: column.value,
    }),
    {}
  );
}

export function findUserById(
  userId: string,
  supportUsers: SupportUser[]
): SupportUser | undefined {
  return supportUsers.find((user) => user.userID === userId);
}

export function getUserName(
  userId: string,
  supportUsers: SupportUser[]
): string {
  const user = findUserById(userId, supportUsers);
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
}

export async function getUserData(): Promise<{
  user?: SupportUser;
  dataRetrieveError?: string;
}> {
  const user = await getUserRecord();

  const userRec = user && user.data ? user.data : null;

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (userRec) {
    window.localStorage.setItem('userTimezone', userTimezone || '');
    return {
      user: {
        firstName: userRec.firstName,
        lastName: userRec.lastName,
        email: userRec.email,
        userName: userRec.userName,
        username: userRec.userName,
        userTimezone: userTimezone,
        name: `${userRec.firstName} ${userRec.lastName}`,
        language: 'en',
        userID: userRec.userID,
        role: userRec.shopRole,
        addedOn: userRec.shopAddedOn,
        addedBy: userRec.shopAddedBy,
        userState: userRec.userState,
        ...userRec,
      },
    };
  } else {
    return {
      dataRetrieveError: user.error
        ? user.error
        : 'Unknown data retrieval error. Maybe login token expired. Please login again.',
    };
  }
}

export function capitalize(word: string): string {
  if (word.length === 0) {
    return word;
  }
  const loweredCase = word.toLowerCase();
  return word[0].toUpperCase() + loweredCase.slice(1);
}

export function trimObjFields(obj: Record<string, any>): any {
  // If the value of this object is an Array, Date, or Moment, do not convert it
  // Additionally, trim array fields
  if (obj) {
    if (obj.constructor === Array) {
      let trimmedArray = obj as Array<any>;
      return trimmedArray.map((element) => {
        return trimObjFields(element);
      });
    } else if (
      obj.constructor === Date ||
      isMoment(obj) ||
      typeof obj === 'string'
    ) {
      return obj;
    }
  }

  let newObj = { ...obj };
  for (const key in newObj) {
    if (typeof newObj[key] === 'string') {
      newObj[key] = newObj[key].trim();
    }
    // recursive into objects
    if (typeof newObj[key] === 'object') {
      newObj[key] = trimObjFields(newObj[key]);
    }
  }
  return newObj;
}

export function formatPhoneNumber(query: string) {
  return query.replace(/\D/g, '');
}

export function setRecentSearches(key: string, values: string[]) {
  window.localStorage.setItem(
    key.replace(' ', '') + 'RecentSearches',
    JSON.stringify(values)
  );
}

export function getRecentSearches(key: string) {
  return JSON.parse(
    window.localStorage.getItem(
      (key.replace(' ', '') + 'RecentSearches') as string
    ) as string
  ) as string[];
}

export function clearRecentSearches(key: string) {
  window.localStorage.setItem(
    key.replace(' ', '') + 'RecentSearches',
    JSON.stringify([])
  );
}

export function getShopStateVarDesc(varName: string, key: string) {
  let varDescMap = {
    shopState: {
      ACTIVE: 'Everything is dandy.',
      INACTIVE:
        'Shop has been closed down permanently. User can’t use tools and can’t log in. (This state is manual only)',
      CHARGEBACKRECEIVED:
        'The owner has initiated a chargeback: Everything still works but the user gets notified on login of the Chargeback and it’s ramifications.',
      SUSPENDED:
        'Tools do not work but users can log into the webapp. This can happen for various reasons:  1) Manually via the backend command line tool or the support portal, 2) payment is past due over 7 days, or 3) we lose a chargeback dispute.',
    },
    crmState: {
      ACTIVE: 'Everything is dandy.',
      INACTIVE:
        'CRM has been disabled. User can’t access CRM features. (This state is manual only)',
      CHARGEBACKRECEIVED:
        'The owner has initiated a chargeback: Everything still works but the user gets notified on login of the Chargeback and it’s ramifications.',
      SUSPENDED:
        'CRM features are disabled. This can happen for various reasons:  1) Manually via the backend command line tool or the support portal, 2) payment is past due over 7 days, or 3) we lose a chargeback dispute.',
    },
    shopSubscriptionState: {
      VALID: 'Active subscription and payment is current',
      PAST_DUE:
        'Subscription has gone past due, i.e., last payment was declined',
      TERMINATED:
        'Shop has been closed. Tools won’t work and Braintree subscription has been canceled.  User can still log into the portal and reinstate if they want.',
      USER_CANCEL:
        'User has pressed the Cancel button on the Shop Profile page. The Braintree subscription has been canceled but the tools will still work until after the paidThroughDate.',
    },
    crmSubscriptionState: {
      VALID: 'Active subscription and payment is current',
      PAST_DUE:
        'Subscription has gone past due, i.e., last payment was declined',
      TERMINATED:
        'Braintree subscription has been canceled.  User can still log into the portal and reinstate if they want.',
      USER_CANCEL:
        'User has pressed the Cancel button on the Shop Profile page. The Braintree subscription has been canceled but CRM features are still available until after the paidThroughDate.',
    },
    braintreeSubscriptionState: {
      Active: 'Everything is dandy.',
      Pending:
        'Braintree subscription will not be active until the next renewal date. This happens when a user removes their payment. Braintree will not let us have an active subscription without a payment method so their subscription is canceled.  When they add back a new form of payment we charge next payment manually if necessary and add a ‘pending’ subscription until the next renewal date.  This does not affect the tools.',
      Canceled:
        'Braintree subscription has been canceled because the user removed their payment method. This does not affect the tools.',
    },
    crmBraintreeSubscriptionState: {
      Active: 'Everything is dandy.',
      Pending:
        'Braintree subscription will not be active until the next renewal date. This does not affect their CRM features.',
      Canceled:
        'Braintree subscription has been canceled. This does not affect their CRM features.',
    },
    shopUserState: {
      ACTIVE: 'User is allowed to use tools in the shop.',
      INACTIVE: 'User is not allowed to use tools in the shop.',
    },
    crmFreeTrialUsed: {
      USED: 'User has activated their free trial for CRM.',
      UNUSED: 'User has not activated their free trial for CRM',
    }
  };

  let stateVar = varDescMap[varName as keyof typeof varDescMap];
  if (!stateVar) return '';
  let value = stateVar[key as keyof typeof stateVar];

  return value;
}

export function getStateVarColor(stateVar: string) {
  switch (stateVar) {
    case 'ACTIVE':
    case 'VALID':
    case 'Active':
      return '#30a629';

    case 'INACTIVE':
    case 'TERMINATED':
    case 'CANCELED':
    case 'Canceled':
    case 'Payment method removed':
      return '#e61717';

    case 'SUSPENDED':
    case 'PAST_DUE':
      return '#ffa500';

    case 'CHARGEBACKRECEIVED':
    case 'USER_CANCEL':
      return '#cc8899';
  }
}

export function getCardIssuer(issuer: string) {
  switch (issuer) {
    case 'American Express':
      return 'amex';
    case 'China UnionPay':
      return 'unionpay';
    case 'Visa':
    case 'MasterCard':
    case 'Discover':
    case 'Elo':
    case 'JCB':
    case 'Laser':
    case 'Maestro':
      return issuer.toLowerCase();
    default:
      return '';
  }
}

export function getCreditCardString(issuer: string) {
  switch (issuer) {
    case 'American Express':
      return '•••• •••••• •';
    default:
      return '•••• •••• ••••';
  }
}

export function storeClipboard(object: { [k: string]: any } | string) {
  let objToStore = JSON.stringify(object, null, 2);
  // LocalStorage can only handle up to 5MB per origin (key),
  // 1 character = 1 Byte
  // 1 Byte x 5 Mil. = 5MB
  // Update pageData only in data < ~4.5MB
  if (objToStore.length <= 4500000) window.localStorage.setItem('pageData', objToStore);
}


export async function setIDXDB(currentTime: any) {
  let db: any;
  const shopNames = await getAllShopNames();

  window.localStorage.setItem('shopNamesAge', currentTime.toString());
  const request = window.indexedDB.open("ShopNames", 1);
  request.onerror = (event: any) => {
    // Handle errors.
    console.log('Error encountered:', event);
    return event;
  };

  request.onupgradeneeded = async (event: any) => {
    db = event.target.result;
    db.onerror = (event: any) => {
     console.log("Error loading database.");
    };
    const shopStore = db.createObjectStore("ShopNames", {keyPath: 'shopID'});
    shopStore.transaction.oncomplete = (event: any) =>{
      const shopObjectStore = db.transaction('ShopNames','readwrite').objectStore('ShopNames');
      shopNames.forEach((shop: any) => shopObjectStore.add(shop));
    }
  }

  request.onsuccess = async (event: any) => {
    db = event.target.result;
    db.onversionchange = function(event: any) {
      event.target.close();
    }
    db.close()
  }

}

export async function getIDXDB(cb: Function) {
  let db: any;
  const request = window.indexedDB.open("ShopNames", 1);
  request.onerror = (event: any) => {
    // Handle errors.
    console.log('Error encountered:', event);
    cb(event);
  };

  request.onupgradeneeded = async (event: any) => {
  }

  request.onsuccess = async (event: any) => {
    let shopResult;
    db = event.target.result;
    const transaction = db?.transaction(['ShopNames']);
    const shopStore = transaction?.objectStore('ShopNames');
    const req = shopStore?.getAll();

    req.onerror = (event: any) => {
      // Handle errors!
      console.log('Error. Please report', event);
      return event;
    };
    req.onsuccess = (event: any) => {
      // Do something with the req.result!
      shopResult = req.result;
      cb(null, shopResult);
    };
    db = event.target.result;
    db.onversionchange = function(event: any) {
      event.target.close();
    }
    db.close()

  }
}

export async function getLocalStorageShopNames(flag: boolean, cb: Function) {
  const isFirefox = navigator.userAgent.match(/firefox|fxios/i);
  if (isFirefox){
    const shopNames = await getAllShopNames();
    cb(null,shopNames)
    return
  }
  const shopNamesAge = parseInt((window.localStorage.getItem('shopNamesAge') as unknown) as string);
  const currentTime = (new Date()).getTime();
  const dbName = 'ShopNames';
  const isExisting = (await window.indexedDB.databases()).map(db => db.name).includes(dbName);
  if (!isExisting || flag ||  !shopNamesAge || (shopNamesAge && (currentTime - shopNamesAge > 3600000 )) ) {
    await setIDXDB(currentTime);
  }
  getIDXDB((err: Error, finalResult: any) => {
    if(err){
      console.log('Error: ', err);
    }
    if(finalResult){
      cb(null, finalResult);
    }

  });
}

