import React, { createContext, useContext, useState, useEffect } from 'react';
import { getVinInfo } from '../libs/db-lib';


// Define the context type
type VinDetails = {
  Make: string;
  Model: string;
  Year: string;
  Trim: string;
};

type VinCacheContextType = {
  getVinInfo: (vin: string) => Promise<VinDetails | undefined>;
};


// create the screen context
export const VinCacheContext = createContext<VinCacheContextType>({
  getVinInfo: (vin: string) => Promise.resolve(undefined),
});

export function useVinCache() {
  return useContext(VinCacheContext);
}

type VinCacheProviderType = {
  children: JSX.Element;
};

const handleGetVinInfo = async (vin: string) => {
  const vinInfo = await getVinInfo([vin]);
  const { Make, Model, Year, Trim } = vinInfo.Responses.vinLookup[0];
  const newVehicleObj = {
    VIN: vin,
    Make: Make    || 'The make could not be determined',
    Model: Model  || 'The model could not be determined',
    Year: Year    || 'The year could not be determined',
    Trim: Trim    || 'The trim could not be determined',
  };
  return newVehicleObj
}

export function VinCacheProvider({ children }: VinCacheProviderType) {

  const [vinCache, setVinCache] = useState<{ [vin: string]: VinDetails }>({});

  const getVinInfo = async (vin: string) => {
    if (vinCache[vin]) {
      return vinCache[vin];
    } else {
      const newVinInfo = await handleGetVinInfo(vin);
      vinCache[vin] = newVinInfo;
      setVinCache(vinCache);
      return newVinInfo;
    }
  }

  const provider = {
    getVinInfo,
  };

  return (
    <VinCacheContext.Provider value={provider}>
      {children}
    </VinCacheContext.Provider>
  );
}
