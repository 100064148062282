import React, { useEffect, useReducer } from 'react';
import { initialState, reducer } from './store';
import FadeIn from 'react-fade-in';
import _ from 'underscore';
import moment from 'moment';

import FloatingButton from '../../../components/FloatingButton';
import { Header } from '../../../components/Header';
import LoaderButton from '../../../components/LoaderButton';
import CustomDatePicker from '../../../components/CustomDatePicker';
import GenericTable from '../../../components/GenericTable';
import CustomReactSelect from '../../../components/CustomReactSelect';
import Switch from '../../../components/Switch';
import { OptionType } from '../../../components/ReactSelect/Option';
import FieldWithHistory from '../../../components/FieldWithHistory';

import { getUserSummary, getEmailLog } from '../../../libs/db-lib';
import {
  usernameValidate,
  isValidEmail,
  uuidValidate,
  formatTimezone,
} from '../../../libs/utils';
import {
  getRecentSearches,
  setRecentSearches,
  storeClipboard,
} from '../../../libs/utils-ts';

import {
  allFilterKeys,
  logTableStructure,
} from '../../../libs/tables/logTableStructure';
import { baseEmailLogColumns } from '../../../libs/tables/columnDefinitions';
import { EmailLogSubcomponent } from '../../../libs/tables/subComponents';

import { ChildProps, InternalLog, QueriedUser } from '../../../types';

import {
  setLog,
  setGroupedLog,
  setQuery,
  setQueryText,
  setQueryType,
  setQueryError,
  setStartDate,
  setEndDate,
  setSelectedActionCodes,
  setIsLoading,
  setQueriedUser,
  setTabset,
  setShowLog,
  setShowUserIDCol,
  setShowActionCodeCol,
  setEnterPressed,
} from './store/actions';
import CopyData from '../../../components/CopyData';

let state = initialState;

const GetEmailLog = (props: ChildProps) => {
  const [getEmailLogStore, dispatch] = useReducer(reducer, state);

  const {
    log,
    groupedLog,
    queryText,
    queryType,
    queryError,
    startDate,
    endDate,
    selectedActionCodes,
    isLoading,
    queriedUser,
    tabset,
    showLog,
    showUserIDCol,
    showActionCodeCol,
    enterPressed,
  } = getEmailLogStore;

  let { query } = getEmailLogStore;

  const topRef = React.createRef<HTMLDivElement>();

  state = { ...getEmailLogStore, isLoading: false };

  let pageData: { [k: string]: any } = {
    'Query Type': queryType,
    Query: query,
    'Date Range': `${startDate} to ${endDate}`,
    'Email Log Count': log.length,
    'Email Log': log,
  };

  storeClipboard(pageData);

  useEffect(() => {
    let groups = log.reduce((group: any, entry: any) => {
      group[entry.actionCode] = group[entry.actionCode] || [];
      group[entry.actionCode].push(entry);
      return group;
    }, Object.create(null));
    setGroupedLog(dispatch, groups);
  }, [log]);

  const handleChangeQuery = _.debounce((query: string) => {
    let error = '';
    switch (queryType) {
      case 'USERNAME':
        error = !usernameValidate(query.trim())
          ? 'Username must be at least 8 alphanumeric lowercase characters starting with a letter'
          : '';
        query = query.toLowerCase();
        break;
      case 'EMAIL':
        error = !isValidEmail(query.trim()) ? 'Email format is not valid!' : '';
        query = query.toLowerCase();
        break;
      case 'USER ID':
        error = !uuidValidate(query.trim())
          ? 'User ID must be in this format: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
          : '';
        query = query.toLowerCase();
        break;
    }
    setQueryError(dispatch, error);
    setQuery(dispatch, query);
  }, 15);

  const handleChangeQueryType = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setQueryType(dispatch, event.target.value);
    setQueryError(dispatch, '');
    setQueryText(dispatch, '');
  };

  const handleRangeChange = (value: any) => {
    setStartDate(dispatch, value.start);
    setEndDate(dispatch, value.end);
  };

  const validateForm = () => {
    switch (queryType) {
      case 'USERNAME':
        return usernameValidate(query.trim());
      case 'EMAIL':
        return isValidEmail(query.trim());
      case 'USER ID':
        return uuidValidate(query.trim());
      default:
        return query.trim().length > 0;
    }
  };

  const handleSubmit = async (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    setShowLog(dispatch, false);
    setIsLoading(dispatch, true);
    setLog(dispatch, []);
    setSelectedActionCodes(dispatch, []);
    setGroupedLog(dispatch, {} as InternalLog);
    setQueriedUser(dispatch, {} as QueriedUser);

    const startDateStr = moment(startDate).startOf('day').utc().toISOString();
    // If the end date is the current date, make the end date the current moment for most up-to-date logs
    const endDateStr = moment(endDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
      ? moment().utc().toISOString()
      : moment(endDate).endOf('day').utc().toISOString();

    try {
      let result:any = null;
      let noSpaceQueryType = queryType.replace(' ', '')

      query = query.toLowerCase();

      let user = await getUserSummary(noSpaceQueryType, query);

      if(user.error){
        props.handleShowAlert(
          'Get Logs',
          <span>
            {user.error}
          </span>,
          false
        );
        return
      }


      if (queryType === 'USERNAME'){
        setQueriedUser(dispatch, user);
      } else if (queryType === 'EMAIL'){
        setQueriedUser(dispatch, { email: query } as QueriedUser);
      } else {
        setQueriedUser(dispatch, { userID: query } as QueriedUser);
      }

      result = await getEmailLog(
        'USERID',
        user.userID,
        startDateStr,
        endDateStr
      );

      if (!result || Object.hasOwn(result, 'error')) {
        if (result && [401, 403].indexOf(result.error?.status) === -1) {
          props.handleShowAlert('Error', result.error);
        } else {
          props.handleShowAlert('Unknown Error getting email log', '');
        }
        return;
      } else if (result.logEntries.length === 0) {
        props.handleShowAlert(
          'Get Logs',
          <span>
            No logs found for query: <strong>{query}</strong> in that date
            range.
          </span>,
          false
        );
      } else {
        setLog(dispatch, result.logEntries);
        setShowLog(dispatch, true);
      }
    } catch (e: any) {
      props.handleShowAlert('Error', e.message);
    } finally {
      setIsLoading(dispatch, false);
      let recentSearches = getRecentSearches(queryType);

      if (recentSearches) {
        if (recentSearches.length > 4) recentSearches.pop();
        if (recentSearches.indexOf(query) === -1) recentSearches.unshift(query);
      } else {
        recentSearches = [query];
      }
      setRecentSearches(queryType, recentSearches);
    }
  };

  if (enterPressed) {
    handleSubmit();
    setEnterPressed(dispatch, false);
  }

  const getPlaceholder = () => {
    switch (queryType) {
      case 'USERNAME':
        return "ex. 'john.smith'";
      case 'EMAIL':
        return "ex. 'john.smith@email.com'";
      case 'USER ID':
        return "ex. 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'";
    }
  };

  const getQueryTypeHelper = () => {
    switch (queryType) {
      case 'USERNAME':
      case 'EMAIL':
      case 'USER ID':
        return `${queryType} (lowercase)`;
    }
  };

  return (
    <>
      <Header context="User Functions" title="Get Email Log" />
      <FloatingButton
        onClick={() => {
          if (topRef && topRef.current) {
            topRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }}
      />
      <div className="l-container" ref={topRef}>
        <form onSubmit={handleSubmit}>
          <div className="c-field">
            <label htmlFor="queryType" className="c-field__label">
              Query Type
            </label>
            <div className="c-select">
              <select
                onChange={handleChangeQueryType}
                id="queryType"
                defaultValue="USERNAME"
              >
                {['USERNAME', 'EMAIL', 'USER ID'].map((type, i) => {
                  return <option key={i}>{type}</option>;
                })}
              </select>
            </div>
          </div>
          <div className={`c-field ${queryError && 'u-margin-bottom-none'}`}>
            <label
              htmlFor="query"
              className="c-field__label u-margin-top-large"
            >
              {getQueryTypeHelper()}
            </label>
            <FieldWithHistory
              fieldId="query"
              searchKey={queryType}
              selected={queryText}
              onInputChange={(e) => {
                if (e !== undefined) {
                  if (['USERNAME', 'EMAIL', 'USER ID'].includes(queryType)) {
                    e = e.toLowerCase();
                  }
                  setQueryText(dispatch, e);
                  handleChangeQuery(e);
                }
              }}
              onChange={(e) => {
                if (e !== undefined) {
                  if (['USERNAME', 'EMAIL', 'USER ID'].includes(queryType)) {
                    e = e.toLowerCase();
                  }
                  setQueryText(dispatch, e);
                  handleChangeQuery(e);
                }
              }}
              placeholder={getPlaceholder()}
              onEnter={() => {
                setEnterPressed(dispatch, true);
              }}
            />
            {queryError && (
              <div className="u-text-error u-margin-left-large">
                <small>{queryError}</small>
              </div>
            )}
          </div>
          <div className="c-field">
            <label
              htmlFor="query"
              className="c-field__label u-margin-top-large"
            >
              Date Range
            </label>
            <CustomDatePicker
              lang="en"
              idPrefix="tool"
              value={{
                start: startDate,
                end: endDate,
                name: 'Last 30 Days',
              }}
              drops={'down'}
              onChange={handleRangeChange}
              user={props.user}
            />
          </div>

          <div className="c-field">
            <LoaderButton
              type="submit"
              disabled={!validateForm()}
              isLoading={isLoading}
              text="Get Log"
              loadingText="Searching..."
            />
          </div>
        </form>
      </div>
      <FadeIn visible={!isLoading && showLog}>
        <div className="u-text-center">
          {queriedUser && (
            <>
              <h2>
                Email Table For User: {queriedUser.email || queriedUser.userID}
              </h2>
            </>
          )}
          <CopyData info={pageData} />
          <div className="c-tabset">
            <span
              className={`c-tab__item ${
                tabset === 'SINGLE' ? 'c-tab__item--active' : ''
              }`}
              onClick={() => setTabset(dispatch, 'SINGLE')}
            >
              Single Table
            </span>
            <span
              className={`c-tab__item ${
                tabset === 'MULTI' ? 'c-tab__item--active' : ''
              }`}
              onClick={() => setTabset(dispatch, 'MULTI')}
            >
              Multiple Table
            </span>
          </div>
        </div>

        {/* Multiple Table View */}
        {tabset === 'MULTI' && (
          <>
            <label
              htmlFor="selectActionCode"
              className="c-field__label u-margin-top-large"
            >
              Select Tables
            </label>
            <div className="c-field">
              <CustomReactSelect
                id="selectActionCode"
                options={Object.keys(groupedLog).map((key) => {
                  return { value: key, label: key } as OptionType;
                })}
                placeholder="Select Action Code(s)..."
                value={selectedActionCodes}
                onChange={(e) => setSelectedActionCodes(dispatch, e)}
              />
            </div>
            <div className="c-field">
              <Switch
                isChecked={showUserIDCol}
                states={{
                  active: 'Show User ID Column',
                  inactive: 'Show User ID Column',
                }}
                handleCheck={(e) => setShowUserIDCol(dispatch, e)}
              />
              <Switch
                isChecked={showActionCodeCol}
                states={{
                  active: 'Show Action Code Column',
                  inactive: 'Show Action Code Column',
                }}
                handleCheck={(e) => setShowActionCodeCol(dispatch, e)}
              />
            </div>

            {Object.keys(groupedLog).map((group) => {
              let actionCodeArr = selectedActionCodes.map(
                (action: OptionType) => {
                  return action.value;
                }
              );
              if (actionCodeArr.indexOf(group) === -1) {
                return null;
              }
              let groupStructure =
                logTableStructure[group as keyof typeof logTableStructure];
              let groupData = groupedLog[group as keyof InternalLog];
              return (
                <div key={group}>
                  <br />
                  <hr />
                  <h3>{group}</h3>
                  <GenericTable
                    id={`${group}-table`}
                    data={(groupData as any[]) || []}
                    filterKeys={groupStructure.filterKeys}
                    columnDefs={groupStructure.columnDefs.filter((col) => {
                      if (col.accessor === 'userID' && !showUserIDCol) {
                        return null;
                      }
                      if (col.accessor === 'actionCode' && !showActionCodeCol) {
                        return null;
                      }
                      return col;
                    })}
                    defaultSorted={[
                      {
                        id: 'actionDate',
                        desc: true,
                      },
                    ]}
                    defaultPageSize={5}
                    savedColumnsId={groupStructure.savedColumnsId}
                    subComponentRenderer={EmailLogSubcomponent}
                  />
                </div>
              );
            })}
          </>
        )}

        {/* Single Table View */}
        {tabset === 'SINGLE' && (
          <>
            <GenericTable
              id="EMAIL-table"
              data={log}
              filterKeys={allFilterKeys}
              columnDefs={baseEmailLogColumns.slice(1)}
              defaultSorted={[
                {
                  id: 'actionDate',
                  desc: true,
                },
              ]}
              savedColumnsId={'fullLogTableColumns'}
              subComponentRenderer={EmailLogSubcomponent}
            />
          </>
        )}
      </FadeIn>
    </>
  );
};

export default GetEmailLog;
