import DateRangePicker from 'react-bootstrap-daterangepicker';
import React, { Component } from 'react';
import moment from 'moment';
import { TextOnly } from '../components/Text';

export default class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      start: props.value.start,
      end: props.value.end,
      rangeName: 'Last 30 Days',
      key: 0,
    };
  }

  componentDidMount() {
    document.getElementById(
      (this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'
    ).value = this.calcRangeStr(this.props.value.start, this.props.value.end);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.setState({ key: this.state.key + 1 });
    }
  }

  handleRangeChange(range) {
    const startStr = range.start.format('YYYY-MM-DD');
    const endStr = range.end.format('YYYY-MM-DD');
    document.getElementById(
      (this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'
    ).value = this.calcRangeStr(startStr, endStr);
    if (this.props.onChange) {
      this.props.onChange({
        start: startStr,
        end: endStr,
      });
    }
  }

  calcRangeStr(start, end) {
    return (
      <span>
        <span>{start}</span>{' '}
        <i className="fal fa-arrow-right"></i>{' '}
        <span>{end}</span>
      </span>
    );
  }

  render() {

    let today = TextOnly('Today');
    let yesterday = TextOnly('yesterday');
    let last30days = TextOnly('last30Days');
    let thisMonth = TextOnly('thisMonth');
    let lastMonth = TextOnly('lastMonth');
    let last365days = TextOnly('last365days');
    let last3years = TextOnly('last3years');
    let thisYear = TextOnly('thisYear');
    let lastYear = TextOnly('lastYear');

    const currentDate = moment().format('YYYY-MM-DD');
    const yesterdayDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const last30StartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const thisMonthStartDate = moment().startOf('month').format('YYYY-MM-DD');
    const lastMonthStartDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    const lastMonthEndDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    const last365StartDate = moment().subtract(365, 'days').format('YYYY-MM-DD');
    const last3YearsStartDate = moment().subtract(3, 'years').format('YYYY-MM-DD');
    const thisYearStartDate = moment().startOf('year').format('YYYY-MM-DD');
    const lastYearStartDate = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
    const lastYearEndDate = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD');

    // The 'range' property is a 2-element array containing functions that return a particular moment in
    // time. This is done so that these moments can be lazily computed when a saved search is loaded.
    let ranges = {};
    ranges[today] = [currentDate, currentDate];
    ranges[yesterday] = [yesterdayDate, yesterdayDate];
    ranges[last30days] = [last30StartDate, currentDate];
    ranges[thisMonth] = [thisMonthStartDate, currentDate];
    ranges[lastMonth] = [lastMonthStartDate, lastMonthEndDate];
    ranges[last365days] = [last365StartDate, currentDate];
    ranges[last3years] = [last3YearsStartDate, currentDate];
    ranges[thisYear] = [thisYearStartDate, currentDate];
    ranges[lastYear] = [lastYearStartDate, lastYearEndDate];

    const locale = {
      language: TextOnly('hl'),
      format: 'YYYY-MM-DD',
      separator: ' - ',
      applyLabel: TextOnly('apply'),
      cancelLabel: TextOnly('cancel'),
      monthNames: [
        TextOnly('January'),
        TextOnly('February'),
        TextOnly('March'),
        TextOnly('April'),
        TextOnly('May'),
        TextOnly('June'),
        TextOnly('July'),
        TextOnly('August'),
        TextOnly('September'),
        TextOnly('October'),
        TextOnly('November'),
        TextOnly('December'),
      ],
      daysOfWeek: [
        TextOnly('Sun'),
        TextOnly('Mon'),
        TextOnly('Tue'),
        TextOnly('Wed'),
        TextOnly('Thu'),
        TextOnly('Fri'),
        TextOnly('Sat'),
      ],
    };

    const startDateStr = this.props.value.start
      && typeof this.props.value.start === 'string'
        ? this.props.value.start
        : '';
    const endDateStr = this.props.value.end
      && typeof this.props.value.end === 'string'
        ? this.props.value.end
        : '';

    return (
      <DateRangePicker
        key={this.state.key}
        useCurrent={false}
        initialSettings={{
          startDate: this.props.value.start,
          endDate: this.props.value.end,
          ranges: ranges,
          locale,
          containerClass: 'width-100',
          showDropdowns: true,
          alwaysShowCalendars: true,
          timePicker: false,
          drops: this.props.drops || 'auto',
          showCustomRangeLabel: false,
        }}
        onApply={(event, dateRangePicker) => {
          const { startDate, endDate } = dateRangePicker;
          this.handleRangeChange({
            start: startDate,
            end: endDate,
            name: 'Custom',
          });
          this.setState({ rangeName: 'Custom' });
        }}
      >
        <div
          className={`Bootstrap__dateRangePicker ${this.props.className}`}
          id={(this.props.idPrefix ? this.props.idPrefix : '') + 'dateRange'}
        >
          <span>{startDateStr}</span>{' '}
            <i className="fal fa-arrow-right"></i>{' '}
          <span>{endDateStr}</span>{' '}
        </div>
      </DateRangePicker>
    );
  }
}
