import React, { useState, useEffect } from 'react';
import moment from 'moment';

import CustomDatePicker from '../../components/CustomDatePicker';
import CustomReactSelect from '../../components/CustomReactSelect';
import GenericTable from '../../components/GenericTable';
import LoaderButton from '../../components/LoaderButton';
import { OptionType } from '../../components/ReactSelect/Option';
import Switch from '../../components/Switch';

import { getShopLog } from '../../libs/db-lib';

import { updateLogData } from '../../libs/utils';

import { logTableStructure } from '../../libs/tables/logTableStructure';
import { LogSubcomponent } from '../../libs/tables/subComponents';
import { baseLogColumns } from '../../libs/tables/columnDefinitions';

import { Log, GenericObj } from '../../types';
import { storeClipboard } from '../../libs/utils-ts';


const ShopLogStandalone = (props: any) => {
  const [startDate, setStartDate] = useState(
    moment.utc().subtract(30, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment.utc().format('YYYY-MM-DD'));
  const [log, setLog] = useState(props.shop?.shopLog || [] as Log[]);
  const [groupedLog, setGroupedLog] = useState({} as Log);
  const [selectedActionCodes, setSelectedActionCodes] = useState(
    [] as OptionType[]
  );
  const [showLog, setShowLog] = useState(props.shop?.shopLog?.length ? true : false);
  const [showShopIDCol, setShowShopIDCol] = useState(true);
  const [showActionCodeCol, setShowActionCodeCol] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [shouldScroll, setShouldScroll] = useState(false);

  const { shop, user, index, handleShowAlert, onChange, oemInfo } = props;

  const topRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const updateAndGroupLogs = async () => {
      await updateLogData(log, oemInfo);
      let groups = log.reduce((group: any, entry: any) => {
        group[entry.actionCode] = group[entry.actionCode] || [];
        group[entry.actionCode].push(entry);
        return group;
      }, Object.create(null));
      setGroupedLog(groups);
    }
    updateAndGroupLogs();
  }, [log]);

  useEffect(() => {
    if (topRef && topRef.current && log.length !== 0 && shouldScroll) {
      topRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setShouldScroll(false);
    }
  }, [log, topRef, shouldScroll]);

  const handleRangeChange = (value: any) => {
    setStartDate(value.start);
    setEndDate(value.end);
  };

  const fetchLogs = async (shopID: string) => {
    setIsLoading(true);
    setShowLog(false);
    setGroupedLog({} as Log);

    const startDateStr = moment(startDate).startOf('day').utc().toISOString();
    // If the end date is the current date, make the end date the current moment for most up-to-date logs
    const endDateStr = endDate === moment().format('YYYY-MM-DD')
      ? moment().utc().toISOString()
      : moment(endDate).endOf('day').utc().toISOString();
    let result;

    try {
      result = await getShopLog(shopID, startDateStr, endDateStr);
      if (!result || Object.hasOwn(result, 'error')) {
        if ([401, 403].indexOf(result.error.status) === -1) {
          handleShowAlert('Error', result.error);
        }
        return;
      } else if (result.logEntries.length === 0) {
        handleShowAlert(
          'Get Logs',
          <span>
            No logs found for this shop: <strong>{shopID}</strong> in that date
            range.
          </span>,
          false
        );
      } else {
        let shops = JSON.parse(
          window.localStorage.getItem('pageData') as string
        );

        shops[index]['shopLog'] = result.logEntries;
        storeClipboard(shops);

        setLog(result.logEntries);
        setShowLog(true);
      }
    } catch (e: any) {
      if (!e || Object.hasOwn(e, 'error')) {
        if ([401, 403].indexOf(e.error.status) === -1) {
          handleShowAlert('Error', e.message);
        } else {
          return;
        }
      }
    } finally {
      setIsLoading(false);
      onChange(shopID, result.logEntries);
    }
  };

  return (
    <React.Fragment>
      <div className="l-flex-between l-flex-align-center">
        <div className="c-field">
          <label htmlFor="query" className="c-field__label u-margin-top-large">
            Date Range
          </label>
          <CustomDatePicker
            lang="en"
            idPrefix="tool"
            value={{
              start: startDate,
              end: endDate,
              name: 'Last 30 Days',
            }}
            drops={'down'}
            onChange={handleRangeChange}
            user={user}
          />
        </div>
        <LoaderButton
          onClick={() => {
            fetchLogs(shop.shopID);
            const shopInfoElement = document.getElementById(shop.shopID);
            if (shopInfoElement) {
              shopInfoElement.style.maxHeight = 'fit-content';
            }
          }}
          type="button"
          icon="c-btn__icon fa fa-chevron-down"
          isLoading={isLoading}
          text="Get Logs"
          loadingText="Getting logs..."
        />
      </div>
      {showLog && !isLoading && (
        <>
          <div className="u-text-center" ref={topRef}>
            <h2>
              Log Table For Shop:{' '}
              <span className="u-font-mono">{`${shop.shopName} (${shop.shopID})`}</span>
            </h2>
          </div>
          <GenericTable
            data={log || []}
            filterKeys={['actionMessage', 'actionCode', 'actionDate']}
            columnDefs={baseLogColumns.slice(1)}
            defaultSorted={[
              {
                id: 'actionDate',
                desc: true,
              },
            ]}
            savedColumnsId={'fullLogTableColumns'}
            subComponentRenderer={LogSubcomponent}
            getTrProps={(state: any, rowInfo: any, column: any) => {
              if (rowInfo) {
                if (rowInfo.original.actionCode === 'UPDATE_USER_INFO') {
                  return {
                    className: 'hide-expander',
                  };
                }
              }
              return {};
            }}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default ShopLogStandalone;
