import React from 'react';
import './NotFound.css';
import { Text } from '../components/Text';

export default () => (
  <div>
    <h3>
      <Text tid="pageNotFound" />
    </h3>
  </div>
);
