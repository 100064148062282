import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-table/react-table.css';
import '@reach/listbox/styles.css';
import '@reach/dialog/styles.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import App from './App';
import { ToastContainer } from 'react-toastify';
import registerServiceWorker from './registerServiceWorker';
import config from './config';
import { createBrowserHistory } from 'history';
import { LanguageProvider } from './libs/Language';
import { MenuProvider } from './context/Menu';
import { UserProvider } from './context/User';
import { ScrollToTop } from './components/ScrollToTop';
import { VinCacheProvider } from './context/VinCache';

const history = createBrowserHistory();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'echo-dev',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: 'authdiag-backend-support-dev',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

const queryClient = new QueryClient();

// if (config.AUTH_DIAG_ENVIRONMENT !== AA_ENV.DEV) {
//   FullStory.init({ orgId: '15DHKH' });
// }

ReactDOM.render(

  <QueryClientProvider client={queryClient}>
    <LanguageProvider>
      <UserProvider>
        <VinCacheProvider>
          <Router history={history}>
            <MenuProvider>
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </MenuProvider>
          </Router>
        </VinCacheProvider>
      </UserProvider>
    </LanguageProvider>
    <ToastContainer
      enableMultiContainer
      containerId={'standard'}
      autoClose={3500}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{
        zIndex: 10000,
      }}
    />
    <ToastContainer
      className={'wide-toast'}
      enableMultiContainer
      containerId={'wide'}
      autoClose={3500}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{
        zIndex: 10000,
      }}
    />
  </QueryClientProvider>,
  document.getElementById('root')
);
registerServiceWorker();
