import React from 'react';
import { systemInfo } from '../libs/utils';
import { Text } from '../components/Text';

export default function SystemInfo() {
  let sysInfo = systemInfo();

  return (
    <div className="l-container u-margin-top-large">
      <div className="l-cards-grid">
        <div className="c-box">
          <h1 className="c-box__title">
            <Text tid="systemInfo" />
          </h1>
          <section className="c-section">
            <label className="c-section__label">
              <Text tid="browser" />:
            </label>
            <div className="c-section__content">
              <span className="c-section__key">
                {sysInfo.browserName} {sysInfo.browserVersion}
              </span>
            </div>
          </section>
          <section className="c-section">
            <label className="c-section__label">
              <Text tid="hostOS" />:
            </label>
            <div className="c-section__content">
              <span className="c-section__key">{sysInfo.hostOS}</span>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
