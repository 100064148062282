import React, { FC, useEffect, useState } from 'react';
import { cardBodyBuilder } from '../../libs/tables/subComponents';
import { useVinCache } from '../../context/VinCache';

interface VehicleRelatedProps {
  vin: string
}
const VehicleRelated: FC<VehicleRelatedProps> = ({vin}) => {
  const { getVinInfo } = useVinCache()
  const [vehicleObj, setVehicleObj] = useState<any>({
    VIN:     vin,
    Make:   'Loading...',
    Model:  'Loading...',
    Year:   'Loading...',
    Trim:   'Loading...',
  });

  const handleGetVinInfo = async () => setVehicleObj(await getVinInfo(vin));

  useEffect(()=>{
    if (vin) handleGetVinInfo();
  }, []);

  return !vin ? null : (
    <div className='flex-1 u-margin-bottom'>
      <div className="c-card">
        <h3 className="c-card__title u-margin-none">Vehicle Related</h3>
        <div className="c-card__description u-margin-none">
          <table className="card-table text-smaller">
            <colgroup>
              <col className="card-table-col-labels-20" />
              <col />
            </colgroup>
            <tbody>{cardBodyBuilder(vehicleObj)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VehicleRelated;