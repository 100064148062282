import moment from 'moment';
import { Log, QueriedUser } from '../../../../types';
import {
  SET_LOG,
  SET_GROUPED_LOG,
  SET_QUERY,
  SET_QUERY_TEXT,
  SET_QUERY_TYPE,
  SET_QUERY_ERROR,
  SET_START_DATE,
  SET_END_DATE,
  SET_SELECTED_ACTION_CODES,
  SET_IS_LOADING,
  SET_QUERIED_USER,
  SET_TABSET,
  SET_SHOW_LOG,
  SET_SHOW_USERID_COL,
  SET_SHOW_ACTION_CODE_COL,
  SET_ENTER_PRESSED,
} from './actions';

export const initialState = {
  log: [],
  groupedLog: {} as Log,
  query: '',
  queryText: '',
  queryType: 'USERNAME',
  queryError: '',
  startDate: moment.utc().subtract(30, 'days').format('YYYY-MM-DD'),
  endDate: moment.utc().format('YYYY-MM-DD'),
  selectedActionCodes: [],
  isLoading: false,
  queriedUser: {} as QueriedUser,
  tabset: 'SINGLE',
  showLog: false,
  showUserIDCol: true,
  showActionCodeCol: true,
  enterPressed: false,
};

export const reducer = (
  state: typeof initialState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOG:
      return { ...state, log: payload };
    case SET_GROUPED_LOG:
      return { ...state, groupedLog: payload };
    case SET_QUERY:
      return { ...state, query: payload };
    case SET_QUERY_TEXT:
      return { ...state, queryText: payload };
    case SET_QUERY_TYPE:
      return { ...state, queryType: payload };
    case SET_QUERY_ERROR:
      return { ...state, queryError: payload };
    case SET_START_DATE:
      return { ...state, startDate: payload };
    case SET_END_DATE:
      return { ...state, endDate: payload };
    case SET_SELECTED_ACTION_CODES:
      return { ...state, selectedActionCodes: payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case SET_QUERIED_USER:
      return { ...state, queriedUser: payload };
    case SET_TABSET:
      return { ...state, tabset: payload };
    case SET_SHOW_LOG:
      return { ...state, showLog: payload };
    case SET_SHOW_USERID_COL:
      return { ...state, showUserIDCol: payload };
    case SET_SHOW_ACTION_CODE_COL:
      return { ...state, showActionCodeCol: payload };
    case SET_ENTER_PRESSED:
      return { ...state, enterPressed: payload };
    default:
      return state;
  }
};
